<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-card :bordered='false'>
      <span slot='title'>
        <a-tag v-if='order.status=="paying"' color='yellow'>待付款</a-tag>
        <a-tag v-else-if='order.status=="delivering"' color='red'>{{order.bussType == 'draw' ? '待提货' : '待发货'}}</a-tag>
        <a-tag v-else-if='order.status=="receiving"' color='blue'>待收货</a-tag>
        <a-tag v-else-if='order.status=="finished"' color='green'>已完成</a-tag>
        <a-tag v-else color='cyan'>已取消</a-tag>
      </span>

      <a-descriptions title='基本信息' :column="4">
        <a-descriptions-item label='商户'>{{order.merchantName}}</a-descriptions-item>
        <a-descriptions-item label='负责人'>{{order.masterName}}</a-descriptions-item>
        <a-descriptions-item label='手机号'>{{order.merchantMobile}}</a-descriptions-item>
        <a-descriptions-item label='授权编号'>{{order.authCode}}</a-descriptions-item>
      </a-descriptions>
    </a-card>

    <a-card :bordered='false' style="margin-top: 20px;">
      <a-descriptions title='订单信息' :column="4">

        <a-descriptions-item label='订单号'>{{order.id}}</a-descriptions-item>
        <a-descriptions-item label='下单时间'>{{order.createTime}}</a-descriptions-item>
        <a-descriptions-item label='下单方式'>{{order.operatorNickname}}</a-descriptions-item>
        <a-descriptions-item  v-if="!order.backRecord" label='订单留言'><span style="color: #E62734;">{{ order.remark }}</span></a-descriptions-item>
        <a-descriptions-item v-else></a-descriptions-item>

        <a-descriptions-item label='购买人姓名'>
          <span v-if="order.memberShareholder=='1'" class="color-red">
            {{order.nickname}}
          </span>
          <span v-else>
             {{order.nickname}}
          </span>
        </a-descriptions-item>
        <a-descriptions-item label='购买人手机号'>{{order.memberMobile}}</a-descriptions-item>
        <a-descriptions-item label='购买人编码'>{{getUserCode(order.shareholder,order.memberType,order.memberLevel)}}</a-descriptions-item>
        <a-descriptions-item></a-descriptions-item>

        <a-descriptions-item label='推荐人姓名'>
          <span v-if="order.spreaderShareholder=='1'" class="color-red">
            {{order.spreaderMasterName}}
          </span>
          <span v-else>
             {{order.spreaderMasterName}}
          </span>
        </a-descriptions-item>
        <a-descriptions-item label='推荐人手机号'>{{order.spreaderMobile}}</a-descriptions-item>
        <a-descriptions-item label='推荐人编码'>{{getUserCode(order.shareholder,order.spreaderType,order.spreaderLevel)}}</a-descriptions-item>
        <a-descriptions-item label='分公司名称'>{{order.spreaderAreaName}}</a-descriptions-item>
<!--        <a-descriptions-item label='分公司名称'>{{order.memberAreaName}}</a-descriptions-item>-->

        <template v-if="!order.isShowOne">
          <!--          非后台录单显示-->
            <a-descriptions-item label='所属商户编码'>
              <span v-for="(item, index) in getAuthCodeList(order.authCode)" :key="index"
                    :style="{color: index == 1 ? '#1890ff' : ''}">{{ item }}</span>
            </a-descriptions-item>
            <a-descriptions-item label='负责人姓名'>{{order.masterName}}</a-descriptions-item>
            <a-descriptions-item label='商户名称'>{{order.merchantName}}</a-descriptions-item>
            <a-descriptions-item ></a-descriptions-item>
        </template>
        <template v-if="order.isShowOne">
          <!--            商户录单只显示订单金额-->
          <template v-if="order.merchantRecord">
                  <a-descriptions-item label='订单金额' :span="4">{{order.amount}}</a-descriptions-item>
          </template>
          <template v-else>
                  <a-descriptions-item label='订单金额'>{{order.amount}}</a-descriptions-item>

                  <a-descriptions-item label='购买人应付' v-if="order.merchantMember==order.member">{{order.originalMerchantAmount}}</a-descriptions-item>
                  <a-descriptions-item label='购买人应付' v-else>{{order.amount}}</a-descriptions-item>

                  <a-descriptions-item v-if="order.merchantMember==order.member" label='积分抵扣'>{{order.merchantDiscount}}</a-descriptions-item>
                  <a-descriptions-item v-else label='积分抵扣'>{{order.memberDiscount}}</a-descriptions-item>
                  <a-descriptions-item v-if="order.merchantMember && order.merchantMember==order.member" label='转账优惠'>{{order.bankTransDiscount}}</a-descriptions-item>
                  <a-descriptions-item v-else></a-descriptions-item>

                  <template v-if="order.merchantMember==order.member">
                    <!--           商户购买的订单-->
                    <a-descriptions-item label='购买人实付'><span class="color-red">{{order.merchantAmount}}</span></a-descriptions-item>
                    <a-descriptions-item label='购买人支付方式'>{{order.merchantPaymentMethodName }}</a-descriptions-item>
                    <a-descriptions-item label='购买人支付时间' :span="2">{{order.merchantActualPaymentTime}}</a-descriptions-item>
                  </template>
                  <template v-else>
                    <!--           用户购买的订单-->
                    <a-descriptions-item label='购买人实付'><span class="color-red">{{order.consumeAmount}}</span></a-descriptions-item>
                    <a-descriptions-item label='购买人支付方式'>{{order.paymentMethodName }}</a-descriptions-item>
                    <a-descriptions-item label='购买人支付时间' :span="2">{{order.createTime}}</a-descriptions-item>
                  </template>
                </template>
          </template>


        <template v-if="order.isShowTwo">

          <template v-if="order.applyId">
            <!--          首先判断为批量兑换订单,不显示商户相关信息，只显示，商户名称和负责人-->
            <a-descriptions-item label='商户名称'>{{order.merchantName}}</a-descriptions-item>
            <a-descriptions-item label='负责人' :span="4">{{order.masterName}}</a-descriptions-item>
          </template>
          <template v-else>
              <a-descriptions-item label='商户原始应付'>{{order.firstMerchantAmount}}</a-descriptions-item>
              <a-descriptions-item label='商户应付'>{{order.originalMerchantAmount}}</a-descriptions-item>
              <a-descriptions-item label='积分抵扣'>{{order.merchantDiscount}}</a-descriptions-item>
              <a-descriptions-item v-if="order.merchantMember && order.merchantMember!=order.member" label="转账优惠">{{order.bankTransDiscount}}</a-descriptions-item>
              <a-descriptions-item v-else></a-descriptions-item>
              <a-descriptions-item label='商户实付'><span class="color-red">{{order.merchantAmount}}</span></a-descriptions-item>
          </template>


          <a-descriptions-item label='商户支付方式'>{{order.merchantPaymentMethodName}}</a-descriptions-item>
          <a-descriptions-item label='商户支付时间'>{{order.merchantActualPaymentTime}}</a-descriptions-item>
          <!--            merchantPaymentTime-->
          <a-descriptions-item label='所在地区'>{{order.area}}</a-descriptions-item>

<!--          <a-descriptions-item label='核算业绩' :span="4">{{order.checkPerformance}}</a-descriptions-item>-->
        </template>


        <template v-if="order.isShowThree">
          <a-descriptions-item label='总销'>{{order.wealthQuotaMemberName}}</a-descriptions-item>
          <a-descriptions-item label='付款人姓名'>{{order.fuZeRen}}</a-descriptions-item>
          <a-descriptions-item label='转账凭证'>
            <img v-for="(item, index) in order.merchantPaymentProofList" :key="index" :src='item' style="margin-right: 20px;" width='100px' height="100px" preview="1">
          </a-descriptions-item>
          <a-descriptions-item label='转账方式'>{{order.bankTransBuyerName?'他人代付':'本人付'}}</a-descriptions-item>
        </template>

        <template>
          <a-descriptions-item label='购买协议' v-if="order.purchaseAgreementStatus=='1'">
            <img  :preview="true" :src='order.purchaseAgreementUrl' style="margin-right: 20px;" width='100px' height="100px" />
          </a-descriptions-item>
          <a-descriptions-item label='订货协议' v-if="order.orderAgreementStatus=='1'">
            <img  :preview="true" :src='order.orderAgreementUrl' style="margin-right: 20px;" width='100px' height="100px" />
          </a-descriptions-item>
        </template>

<!--        <a-descriptions-item v-if="order.merchantPaymentMethod != 'money'" label='总销'>{{order.merchantBankAccountName}}</a-descriptions-item>-->
<!--        <a-descriptions-item label='支付方式'>{{ order.merchantPaymentMethodName }}</a-descriptions-item>-->
<!--        <a-descriptions-item label='原始应付'>{{order.firstMerchantAmount}}</a-descriptions-item>-->
<!--        <a-descriptions-item label='实付金额'><span :class="font_class">{{order.merchantAmount}}</span></a-descriptions-item>-->
<!--        <a-descriptions-item label='应收金额'>{{order.originalMerchantAmount}}</a-descriptions-item>-->
<!--&lt;!&ndash;        <a-descriptions-item label='应收金额'><span :class="font_class">{{order.originalMerchantAmount}}</span></a-descriptions-item>&ndash;&gt;-->
<!--        <a-descriptions-item label='付款人姓名'>{{order.fuZeRen}}</a-descriptions-item>-->
<!--        <a-descriptions-item label='转账方式' v-if="order.merchantPaymentMethod==='bank_trans'">{{order.bankTransBuyerName?'他人代付':'本人付'}}</a-descriptions-item>-->
<!--        <a-descriptions-item label='转账凭证'>-->
<!--          <img v-for="(item, index) in order.merchantPaymentProof" :key="index" :src='item' style="margin-right: 20px;" width='100px' height="100px" preview="1">-->
<!--        </a-descriptions-item>-->
<!--        <a-descriptions-item label='支付时间'>{{order.merchantActualPaymentTime}}</a-descriptions-item>-->
<!--        <a-descriptions-item label='注册手机号'>{{order.memberMobile}}</a-descriptions-item>-->
<!--        <a-descriptions-item label='录单人'>{{order.operatorNickname}}</a-descriptions-item>-->
      </a-descriptions>
    </a-card>

    <a-card v-if="order.transferList!=null && order.transferList.length > 0" :bordered='false' style="margin-top: 20px;">
      <a-descriptions title='订单转移信息'>
        <a-descriptions-item label='原下单人姓名'>{{order.transferList[0].oldName}}</a-descriptions-item>
        <a-descriptions-item label='被转移人姓名'>{{order.transferList[0].newName}}</a-descriptions-item>
        <a-descriptions-item label='转移订单时间'>{{ order.transferList[0].createTime }}</a-descriptions-item>
        <a-descriptions-item label='操作人'>{{order.transferList[0].creator}}</a-descriptions-item>
        <a-descriptions-item label='订单转移原因'>{{order.transferList[0].reason}}</a-descriptions-item>
      </a-descriptions>
    </a-card>

    <a-card v-if="order.bussType == 'draw'" :bordered='false' style="margin-top: 20px;">
      <span slot='extra'>
        <template>
<!--          自提的-->
          <a-space style="margin-left: 20px">
            <a-button type='primary' @click='initialDeliver3(order)'>
              打印出库单
            </a-button>
          </a-space>
          <a-space style="margin-left: 20px" v-if=" order.status == 'delivering'">
            <a-button type='primary' @click='initialDeliver1(order)'>
              修改商品
            </a-button>
          </a-space>
          <a-popconfirm title='确认提货吗？' ok-text='确认' cancel-text='取消' @confirm='handleDraw(order)'>
            <a-space style="margin-left: 20px">
              <a-button type='primary' v-if="order.status == 'delivering'">
                确认提货
              </a-button>
            </a-space>
          </a-popconfirm>
        </template>
      </span>
      <a-descriptions title='发货信息'>
        <a-descriptions-item label='购买人兑换积分'>
          <span class="font-red">{{this.profile.exchangeIntegralBalance}}</span>
        </a-descriptions-item>
      </a-descriptions>
      <a-descriptions >
        <a-descriptions-item label='订单商品' :span="3">{{order.depositoryProductionDesc}}</a-descriptions-item>
        <a-descriptions-item label='发货方式'>自提现货</a-descriptions-item>
        <a-descriptions-item label='提货人'>{{order.drawName}}</a-descriptions-item>
        <a-descriptions-item label='提货人手机号'>{{order.drawMobile}}</a-descriptions-item>
        <a-descriptions-item label='提货时间'>{{order.sendTime}}</a-descriptions-item>
        <a-descriptions-item label='出库人'>{{order.outMemberName}}</a-descriptions-item>
      </a-descriptions>
    </a-card>

    <a-card v-else :bordered='false' style="margin-top: 20px;">
      <span slot='extra'>
        <template>
<!--          物流-->
          <a-space style="margin-left: 20px">
            <a-button type='primary' @click='initialDeliver2(order)'>
              打印出库单
            </a-button>
          </a-space>
          <a-space style="margin-left: 20px" v-if=" order.status == 'delivering'">
            <a-button type='primary' @click='initialDeliver1(order)'>
              修改商品
            </a-button>
          </a-space>
          <a-space style="margin-left: 20px">
            <a-button type='primary' v-if="order.status == 'delivering'" @click='initialDeliver(order)'>
              确认发货
            </a-button>
          </a-space>
        </template>
      </span>
      <a-descriptions title='收货地址'>
        <a-descriptions-item label='购买人兑换积分'>
          <span class="font-red">{{this.profile.exchangeIntegralBalance}}</span>
        </a-descriptions-item>
      </a-descriptions>
      <a-descriptions>
        <a-descriptions-item label='订单商品' :span="3">{{order.depositoryProductionDesc}}</a-descriptions-item>
        <a-descriptions-item label='发货方式'>物流发货</a-descriptions-item>
        <a-descriptions-item label='联系电话'>{{ address.mobile }}</a-descriptions-item>
        <a-descriptions-item label='详细地址'>
          {{ address.regionOneName }}
          {{ address.regionTwoName }}
          {{ address.regionThreeName }}
          {{ address.address }}
        </a-descriptions-item>
      </a-descriptions>
      <a-divider style='margin-bottom: 32px' />

      <a-descriptions title='物流信息' :column="4">
        <a-descriptions-item label='物流公司'>{{ address.logisticsProvider }}</a-descriptions-item>
        <a-descriptions-item label='物流单号'>{{ address.logisticsNo }}</a-descriptions-item>
        <a-descriptions-item label='物流金额'>{{ address.logisticsAmount }}</a-descriptions-item>        
        <a-descriptions-item label='付费方式'>{{ address.logisticsMethod == 1 ? '到付' : address.logisticsMethod == 2 ? '寄付' : '' }}</a-descriptions-item>
        <a-descriptions-item label='发货时间'>{{order.sendTime}}</a-descriptions-item>
        <a-descriptions-item label='收货时间'>{{order.receiveTime}}</a-descriptions-item>
      </a-descriptions>
    </a-card>

    <a-card v-for="item in (order.outRecordList || [])" :key="item.id" :bordered='false' style="margin-top: 20px;">
      <a-descriptions title='预提货' :column="4">
        <a-descriptions-item label='订单商品' :span="2">{{item.operateDes}}</a-descriptions-item>
        <a-descriptions-item label='预提时间'>{{item.createTime}}</a-descriptions-item>
        <a-descriptions-item label='出库人'>{{item.operatorNickname}}</a-descriptions-item>
      </a-descriptions>
    </a-card>

    <a-card :bordered='false' style="margin-top: 20px;">
      <a-descriptions title='留言' :column="4">
        <a-descriptions-item label='用户留言' :span="4"><span style="color: #E62734;">{{ order.remark }}</span></a-descriptions-item>
        <template v-for="(item, index) in order.outCommentList">
          <a-descriptions-item :key="'1_' + index" label='仓管留言' :span="2">{{item.comment}}</a-descriptions-item>
          <a-descriptions-item :key="'2_' + index" label='仓管留言'>{{item.createTime}}</a-descriptions-item>
          <a-descriptions-item :key="'3_' + index" label='留言人'>{{item.masterName}}</a-descriptions-item>
        </template>
      </a-descriptions>
    </a-card>

    <a-modal  :destroyOnClose='true' :maskClosable='true' :footer = "null"
             :visible='deliver2.visible'
             @cancel='()=>this.deliver2.visible=false'
             @ok='()=>this.deliver2.visible=false'
             cancel-text='关闭'
             :confirmLoading='deliver2.loading'
             width="99%"
    >
<!--        <a-button type='primary' @click='exportImg(order)'>-->
<!--          导出-->
<!--        </a-button>-->

          <div id="printContainer" v-if="ishHow">
            <div style="margin: 20px">
                <template >
                  <a-descriptions title="商品物流出库发货单" class="diyTableStyle_no_Border" style="text-align: center">
                  </a-descriptions>
                  <a-descriptions :column="4" class="diyTableStyle_no_Border">
                    <a-descriptions-item label="订单号" :span="3">{{ order.id }}</a-descriptions-item>
                    <a-descriptions-item label="下单时间" :contentStyle="{textAlign:'right'}">{{ order.showCreateTime }}</a-descriptions-item>
                  </a-descriptions>
                  <a-descriptions bordered  :column="2"   class="diyTableStyle">
                    <a-descriptions-item label="订单商品" :span="2" >{{ order.depositoryProductionDesc }}</a-descriptions-item>

<!--                    <a-descriptions-item label="下单时间" >{{ order.createTime }}</a-descriptions-item>-->
<!--                    <a-descriptions-item v-if="order.orderType =='1'" label="支付时间" >{{ order.auditTime }}</a-descriptions-item>-->
<!--                    <a-descriptions-item v-if="order.orderType =='2'" label="支付时间" >{{ order.merchantPaymentTime }}</a-descriptions-item>-->

                    <a-descriptions-item label="收货人" >{{ order.addressUserName }}</a-descriptions-item>
                    <a-descriptions-item label="联系电话" >{{ order.showAddressUserMobile }}</a-descriptions-item>

                    <a-descriptions-item label="收货地址" :span="2">
                      {{ address.regionOneName }}
                      {{ address.regionTwoName }}
                      {{ address.regionThreeName }}
                      {{ address.address }}
                    </a-descriptions-item>

                    <a-descriptions-item label="卖方" :span="2">{{ order.areaName }}</a-descriptions-item>

                    <a-descriptions-item label="物流公司" >{{ address.logisticsProvider }}</a-descriptions-item>
                    <a-descriptions-item label="物流单号" >{{ address.logisticsNo }}</a-descriptions-item>

                    <a-descriptions-item label="物流金额" >寄付月结</a-descriptions-item>
<!--                    <a-descriptions-item label="物流金额" >{{ address.logisticsAmount }}</a-descriptions-item>-->
                    <a-descriptions-item label="出库时间" >{{ order.sendTime }}</a-descriptions-item>
                  </a-descriptions>

                  <a-descriptions :column="8" class="diyTableStyle_no_Border">
                    <a-descriptions-item label="打印人员" :span="5">{{ order.opUserName }}</a-descriptions-item>
                    <a-descriptions-item label="打印时间" >{{ this.printTime }}</a-descriptions-item>
                  </a-descriptions>
                </template>
            </div>
      </div>
    </a-modal>

    <a-modal  :destroyOnClose='true' :maskClosable='true' :footer = "null"
             :visible='deliver3.visible' @cancel='()=>this.deliver3.visible=false' @ok='()=>this.deliver3.visible=false'
             cancel-text='关闭'  :confirmLoading='deliver3.loading'
             width="99%"
    >
      <template>

        <a-descriptions title="商品提现货出库单" class="diyTableStyle_no_Border" style="text-align: center;">
        </a-descriptions>
        <a-descriptions class="diyTableStyle_no_Border" :column="4">
          <a-descriptions-item label="订单号" :span="3">{{ order.id }}</a-descriptions-item>
          <a-descriptions-item label="下单时间" >{{ order.showCreateTime }}</a-descriptions-item>
        </a-descriptions>
        <a-descriptions bordered :column="2" class="diyTableStyle">
          <a-descriptions-item label="订单商品" :span="2" >{{ order.depositoryProductionDesc }}</a-descriptions-item>

<!--          <a-descriptions-item label="下单时间" >{{ order.createTime }}</a-descriptions-item>-->
<!--          <a-descriptions-item v-if="order.orderType =='1'" label="支付时间" >{{ order.auditTime }}</a-descriptions-item>-->
<!--          <a-descriptions-item v-if="order.orderType =='2'" label="支付时间" >{{ order.merchantPaymentTime }}</a-descriptions-item>-->

          <a-descriptions-item label="提货人" >{{ order.drawName }}</a-descriptions-item>

          <a-descriptions-item label="提货人手机号" >{{ order.showdrawMobile }}</a-descriptions-item>

          <a-descriptions-item label="卖方" :span="2">{{ order.areaName }}</a-descriptions-item>

          <a-descriptions-item label="出库人" >{{ order.outMemberName }}</a-descriptions-item>
          <a-descriptions-item label="出库时间" >{{ order.sendTime }}</a-descriptions-item>


          <a-descriptions-item label="提货人签字确认"></a-descriptions-item>
          <a-descriptions-item label="日期"></a-descriptions-item>
        </a-descriptions>

        <a-descriptions :column="8" class="diyTableStyle_no_Border">
          <a-descriptions-item label="打印人员" :span="5">{{ order.opUserName }}</a-descriptions-item>
          <a-descriptions-item label="打印时间" >{{ this.printTime }}</a-descriptions-item>
        </a-descriptions>
      </template>
    </a-modal>

    <a-modal title='确认发货' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver.visible' @cancel='()=>this.deliver.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleDeliver' :confirmLoading='deliver.loading' :width="800">
      <a-form-model ref='form' :model='deliver.form' :rules='deliver.rules' :labelCol='{ span: 4 }' :wrapperCol='{ span: 20 }'>
        <a-form-model-item label='总需发货'>
          <s-table ref='table1' rowKey='id' size='default' :columns='columns1' :data='loadData1'>
            <span slot='stitle' slot-scope='text'>
              <template>
                  <Ellipsis :lines='2' tooltip :length='20'>{{ text }}</Ellipsis>
              </template>
            </span>
          </s-table>
        </a-form-model-item>
        <a-form-model-item label='订单号'>
          {{deliver.form.id}}
        </a-form-model-item>
        <template v-if="deliver.form.address">
          <a-form-model-item label='收货人'>
            {{deliver.form.name}}
          </a-form-model-item>
          <a-form-model-item label='联系电话'>
            {{deliver.form.mobile}}
          </a-form-model-item>
          <a-form-model-item label='详细地址'>
            {{ deliver.form.regionOneName }}
            {{ deliver.form.regionTwoName }}
            {{ deliver.form.regionThreeName }}
            {{ deliver.form.address }}
          </a-form-model-item>
        </template>
<!--        <a-form-model-item label='物流名称' prop='logisticsProvider'>-->
<!--          <a-select v-model="deliver.form.logisticsProvider" placeholder="请选择">-->
<!--            <a-select-option :value="item" v-for="(item, index) in expressList" :key="index">-->
<!--              {{item}}-->
<!--            </a-select-option>-->
<!--          </a-select>-->
<!--        </a-form-model-item>-->
<!--        <a-form-model-item label='物流单号' prop='logisticsNo'>-->
<!--          <a-input v-model='deliver.form.logisticsNo' />-->
<!--        </a-form-model-item>-->
        <!-- <a-form-model-item label='物流金额' prop='logisticsAmount'>
          <a-input v-model='deliver.form.logisticsAmount' />
        </a-form-model-item>
        <a-form-model-item label='付费方式' prop='logisticsMethod'>
           <a-radio-group name="radioGroup" v-model="deliver.form.logisticsMethod">
            <a-radio :value="1">
              到付
            </a-radio>
            <a-radio :value="2">
              寄付
            </a-radio>
          </a-radio-group>
        </a-form-model-item> -->
      </a-form-model>
    </a-modal>

    <a-modal title='修改商品' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver1.visible' @cancel='()=>this.deliver1.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleDeliver1' :confirmLoading='deliver1.loading' width="50%">
      <a-form-model ref='form1' :model='deliver1.form' :rules='deliver1.rules' :labelCol='{ span: 8 }' :wrapperCol='{ span: 16 }'>
        <a-card style="width: 90%;margin-left: auto;margin-right: auto">

            <a-row v-for="(item, index) in deliver1.form.productionList" :key="index">
              <a-col :span="12">
                <a-form-model-item label='商品名称'>
                  {{item.title}}
                </a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item label='价格'>
                  ￥{{item.price}}
                </a-form-model-item>
              </a-col>
              <a-col :span="4">
                <a-form-model-item label='数量'>
                  <a-input-number v-model='item.quantity' :min="0" />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
              </a-col>
              <a-col :span="12">
                <a-form-model-item label='价格'>
                  共计 {{totalCount}} 件产品，合计：¥{{totalPrice}}
                </a-form-model-item>
              </a-col>
            </a-row>

        </a-card>

        <a-form-model-item label='发货方式' prop='bussType'>
           <a-radio-group name="radioGroup" v-model="deliver1.form.bussType">
            <a-radio value="draw">
              自提现货
            </a-radio>
            <a-radio value="deliver">
              物流发货
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <template v-if="deliver1.form.bussType == 'draw'">
          <a-form-model-item label='提货人' prop='drawName'>
            <a-input v-model='deliver1.form.drawName' />
          </a-form-model-item>
          <a-form-model-item label='提货人手机号' prop='drawMobile'>
            <a-input v-model='deliver1.form.drawMobile' />
          </a-form-model-item>
        </template>
        <template v-else>
          <a-form-model-item label='物流名称' prop='logisticsProvider'>
            <a-select v-model="deliver1.form.logisticsProvider" placeholder="请选择">
              <a-select-option :value="item" v-for="(item, index) in expressList" :key="index">
                {{item}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label='物流单号' prop='logisticsNo'>
            <a-input v-model='deliver1.form.logisticsNo' />
          </a-form-model-item>

          <a-form-model-item label='收货人' prop='name'>
            <a-input v-model='deliver1.form.name' />
          </a-form-model-item>
          <a-form-model-item label='收货人手机号' prop='addressMobile'>
            <a-input v-model='deliver1.form.addressMobile' />
          </a-form-model-item>
          <a-form-model-item label='地区' prop='region'>
            <a-cascader
              :defaultValue="[deliver1.form.regionOneCode, deliver1.form.regionTwoCode, deliver1.form.regionThreeCode]"
              :field-names="{ label: 'name', value: 'adcode', children: 'districts' }"
              :options="region"
              :show-search="{ filter }"
              :allowClear="false"
              placeholder="请选择"
              @change="onChange"
            />
          </a-form-model-item>
          <a-form-model-item label='详细地址' prop='address'>
            <a-input v-model='deliver1.form.address' />
          </a-form-model-item>
        </template>
      </a-form-model>
    </a-modal>



  </page-header-wrapper>
</template>

<script>
import {Ellipsis, STable} from '@/components'
import {
  deliverMemberOrder,
  getMemberOrder,
  getMemberOrderAddress,
  getMemberOrderProductions,
  putOrderDraw,
  putOrderProduction
} from '@/api/member-order'
import {mapGetters} from 'vuex'
import region from '@/config/region'
import {getFuZeRen, getSpreaderMobileList, showOrderType} from '@/utils/util'
import moment from "moment";
import {queryProductions} from "@/api/production";
import {getMember} from "@/api/member";
import html2canvas from 'html2canvas'

const columns1 = [
  {
    width: 150,
    title: '产品名称',
    dataIndex: 'title',
    scopedSlots: { customRender: 'stitle' }
  },
  {
    width: 100,
    title: '购买数量',
    dataIndex: 'quantity',
    scopedSlots: { customRender: 'quantity' }
  },
]

const formatType = 'YYYY-MM-DD HH:mm:ss';

export default {
  name: 'InsideStorehouseOrder',

  data() {
    return {
      printTime:'',
      bank: {},
      order: {},
      profile: {},
      address: {},
      font_class:"",
      ishHow:true,
      deliver: { 
        visible: false,
        loading: false, 
        form: {},
        rules: {
          logisticsNo: [{ required: true, message: '请填写物流单号', trigger: 'change' }],
          logisticsProvider: [{ required: true, message: '请填写物流名称', trigger: 'change' }],
          logisticsAmount: [{ required: true, message: '请填写物流金额', trigger: 'change' }]
        }
      },
      deliver1: {
        visible: false, 
        loading: false,
        form: {},
        rules: {
          bussType: [{ required: true, message: '请选择发货方式', trigger: 'change' }],
          name: [{ required: true, message: '请填写收货人', trigger: 'change' }],
          addressMobile: [{ required: true, message: '请填写收货人手机号', trigger: 'change' }],
          logisticsProvider:[{ required: true, message: '请填写物流名称', trigger: 'change' }],
          logisticsNo:[{ required: true, message: '请填写物流单号', trigger: 'change' }],
          region: [{ required: true, message: '请选择地区', trigger: 'change' }],
          address: [{ required: true, message: '请填写地址', trigger: 'change' }],
          drawName: [{ required: true, message: '请填写提货人', trigger: 'change' }],
          drawMobile: [{ required: true, message: '请填写提货人手机号', trigger: 'change' }]
        }
      },
      deliver2: {
        visible: false,
        loading: false,
      },
      deliver3: {
        visible: false,
        loading: false,
      },
      region: region,
      columns1,
      loadData1: parameter => {
        let queryParam = {};
        if (this.deliver.form.id) {
          queryParam.order = this.deliver.form.id;
        }
        return getMemberOrderProductions(Object.assign(parameter, queryParam))
          .then(datum => {
            return datum
          })
      },
    }
  },
  components: { STable, Ellipsis },
  computed: {
    ...mapGetters(["expressList"]),
    ...mapGetters(['userInfo']),
    // id () {
    //   return this.$route.query.id;
    // },
    totalCount() {
      let count = 0;
      if (this.deliver1.form.productionList) {
        for (const item of this.deliver1.form.productionList) {
          count += item.quantity;
        }
      }
      return count;
    },
    totalPrice() {
      let price = 0;
      if (this.deliver1.form.productionList) {
        for (const item of this.deliver1.form.productionList) {
          item.price = item.price ? item.price : 0;
          price += item.quantity * item.price;
        }
      }
      return price
    }
  },
  activated(){
    this.id = this.$route.query.id
    this.handleInitial();
  },
  created() {
    this.id = this.$route.query.id
    this.handleInitial()
  },
  methods: {
    getSpreaderMobileList,
    handleDraw(record) {
      putOrderDraw({id: record.id}).then(_result => {
        this.$message.success('操作成功')
        this.handleInitial();
      })
    },
    onChange(value, selectedOptions) {
      this.$set(this.deliver1.form, "region", selectedOptions)
    },
    filter(inputValue, path) {
      return path.some(option => option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    },
    initialDeliver1(record) {
      let prams = { current: 1, size: 1000,categoryName:'全部商品', status: 'online' };
      let proList = []
      queryProductions(prams).then(result => {
        record.productions = this.cuProductList(result,record);
        this.initUpdateProductParams(record);
      })

    },
    initialDeliver2(order) {

      let merchantMember = order.merchantMember;
      let areaName = "";

      // 1）用户支付给商户，商户再支付给公司，卖方显示商户所属分公司名称；（有merchantMember）取merchantAreaName;
      // 2）用户/备选/商户下单直接支付给公司的，卖方显示自己所属分公司名称；（没有merchantMember）取memberAreaName;
      if(merchantMember){
          areaName = order.merchantAreaName;
      }else{
          areaName = order.memberAreaName;
      }
      debugger;

      let createTime = order.createTime;
      let addressUserMobile = order.addressUserMobile;
      let tempString = addressUserMobile.toString().substring(0,8)+"***";

      order.showCreateTime = this.getCurrentTime(createTime);
      order.showAddressUserMobile =tempString;
      order.areaName = areaName;

      this.printTime =moment().format(formatType);
      this.deliver2.loading = false
      this.deliver2.visible = true
    },
    getCurrentTime(createTime) {
      const now = new Date(createTime);
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const seconds = now.getSeconds();

      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    },
    initialDeliver3(order) {

      // console.log("&&&&&&&&&")
      let createTime = order.createTime;
      let drawMobile = order.drawMobile;
      if(drawMobile && drawMobile.length>8 ){
        order.showdrawMobile =drawMobile.toString().substring(0, 8) + "***";
      }else{
        order.showdrawMobile =drawMobile;
      }

      let merchantMember = order.merchantMember;
      let areaName = "";

      // 1）用户支付给商户，商户再支付给公司，卖方显示商户所属分公司名称；（有merchantMember）取merchantAreaName;
      // 2）用户/备选/商户下单直接支付给公司的，卖方显示自己所属分公司名称；（没有merchantMember）取memberAreaName;
      if(merchantMember){
        areaName = order.merchantAreaName;
      }else{
        areaName = order.memberAreaName;
      }


      order.showCreateTime = this.getCurrentTime(createTime);

      order.areaName = areaName;

      this.printTime =moment().format(formatType);
      this.deliver3.loading = false
      this.deliver3.visible = true
    },
    handleDeliver1() {
      this.$refs.form1.validate((result) => {

        if (result) {
          this.deliver1.loading = true;
          let region = this.deliver1.form.region;
          if (this.deliver1.form.region && this.deliver1.form.region.length > 0) {
            debugger
            this.deliver1.form.regionOneCode = this.deliver1.form.region[0].adcode;
            this.deliver1.form.regionOneName = this.deliver1.form.region[0].name;
            this.deliver1.form.regionTwoCode = this.deliver1.form.region[1].adcode;
            this.deliver1.form.regionTwoName = this.deliver1.form.region[1].name;
            if(this.deliver1.form.region[2]){
              this.deliver1.form.regionThreeCode = this.deliver1.form.region[2].adcode;
              this.deliver1.form.regionThreeName = this.deliver1.form.region[2].name;
            }

          }

          const parameter = JSON.parse(JSON.stringify(this.deliver1.form));
          delete parameter.region;
          debugger
          putOrderProduction(parameter).then(_result => {
            this.$message.success('操作成功')
            this.handleInitial();
            this.deliver1.visible = false
            this.deliver1.loading = false;
          }).catch(() => {
            this.deliver1.loading = false;
          })
        }
      })
    },
    initUpdateProductParams(record){
      // debugger
      this.deliver1.form = {
        id: record.id,
        productionList: record.productions,
        bussType: record.bussType,
        drawName: record.drawName,
        drawMobile: record.drawMobile,
        logisticsNo:record.logisticsNo,
        logisticsProvider:record.logisticsProvider,
        name: this.address.name,
        addressMobile: this.address.mobile,
        address: this.address.address,
        regionOneCode: this.address.regionOneCode,
        regionOneName: this.address.regionOneName,
        regionTwoCode: this.address.regionTwoCode,
        regionTwoName: this.address.regionTwoName,
        regionThreeCode: this.address.regionThreeCode,
        regionThreeName: this.address.regionThreeName,
      };
      if (this.deliver1.form.regionOneCode && this.deliver1.form.regionTwoCode && this.deliver1.form.regionThreeCode) {
        const province = this.region.find(item => item.adcode == this.deliver1.form.regionOneCode);
        if (province) {
          const city = province.districts.find(item => item.adcode == this.deliver1.form.regionTwoCode);
          if (city) {
            const area = city.districts.find(item => item.adcode == this.deliver1.form.regionThreeCode);
            this.deliver1.form.region = [province, city, area]
          }
        }
      }
      this.deliver1.loading = false
      this.deliver1.visible = true
    },
    initialDeliver(record) {
      getMemberOrderAddress({ order: record.id }).then(result => {

        // this.deliver.form = { id: record.id, logisticsMethod: 2, address: Object.assign({}, this.deliver.form.address, result)}
        let params = Object.assign({id: record.id}, result);
        params.logisticsMethod = 2;
        params.id = record.id;
        this.deliver.form = params;

        this.deliver.visible = true
        this.$nextTick(() => {
          this.$refs.table1.refresh(true);
        })
      })
    },
    handleDeliver() {
      this.$refs.form.validate((result) => {
        if (result) {
          this.deliver.loading = true;
          let params = Object.assign(this.deliver.form)
          debugger;

          deliverMemberOrder(params).then(_result => {
            this.$message.success('操作成功')
            this.handleInitial();
            this.deliver.visible = false
            this.deliver.loading = false;
          }).catch(() => {
            this.deliver.loading = false;
          })
        }
      })
    },
    handleChange(e) {
      if (e) {
        let msg = this.houseList.find(item => item.id == e)
        if (msg) {
          this.deliver.form.depositoryName = msg.depositoryName;
        }
      } else {
        this.deliver.form.depositoryName = "";
      }
    },
    handleInitial() {
      let params = {
        id: this.id
      }

      getMemberOrder(params).then(result => {
        // debugger;
        if(result.batchSelect===1){
          this.font_class="font-red";
        }
        if (result.merchantPaymentProof) {
          result.merchantPaymentProof = result.merchantPaymentProof.split(",");
        } else {
          result.merchantPaymentProof = [];
        }

        result.opUserName = this.$store.getters.userInfo.username;

        result.fuZeRen = getFuZeRen(result);//获取负责人

        result = showOrderType(result);    //公共方法获取显示区域


        this.order = result;

        this.address={};

        getMemberOrderAddress({ order: this.order.id }).then(result => {
          this.address = Object.assign({}, this.address, result)
        })

        //  获取用户详情信息，显示数据
        let mid = result.member;
        getMember({ id:mid}).then(result => {
          this.profile = Object.assign({}, result);
        }).catch(error => {
          this.profile =undefined;
        })

        this.$previewRefresh()
      })
    },
    getAuthCodeList(authCode) {
      if (authCode) {
        let index = authCode.indexOf("-");
        if (index < 0) {
          index = authCode.length - 1;
        } else {
          index--;
        }
        let ownAuthCode = authCode.slice(index - 3, index + 1);
        if (ownAuthCode) {
          const authCodeList = authCode.split(ownAuthCode);
          authCodeList.splice(1, 0, ownAuthCode);
          return authCodeList;
        }
      }
      return [];
    },
    getUserCode(shareholder,type,level){
      // debugger;
      if(shareholder=='1'){
        //股东判断
        return '股东'
      }
      if(type=='store'){
        return '门店'
      }else if(type=='center') {
        // return '服务中心';
        return 'SF';
      }else if(type=='president') {
        // return '运营总裁';
        return 'SY'
      }else if(type=='normal'){
        if(level=='center'){
          // return '备选服务';
          return 'BF';
        } else if(level=='president'){
          // return '备选运营';
          return 'BY';
        }
        // return '用户';
        return 'Y';
      }else{
        return '';
      }
    },
    //计算全部商品列表组合显示
    cuProductList(result,record){
      let pl = result.records;
      let production = record.productions;
      for(let p in pl){
        let it = pl[p]
        it.quantity = 0;
        it.amount = 0;
        it.production = it.id;
        for(let i in production){
          let item = production[i];
          if(item.production==it.id){
            it.isDelete = true;
            break;
          }
        }
      }
      //过滤订单产品，并且合并商品列表
      for(let p in pl){
        let it = pl[p]
        if(!it.isDelete){
          //不存在就添加到产品列表中
          production.push(it);
        }
      }
      return production;
    },
    async exportImg () {
      const content = document.querySelector("#printContainer");
      const canvas = await html2canvas(content)
      document.getElementById("imgOrder").appendChild(canvas);
      this.ishHow=false;

      // const url = canvas.toDataURL('image/png')
      // const filename = 'qrCode.png'
      // const link = document.createElement('a')
      // link.href = url
      // link.download = filename
      // document.body.appendChild(link)
      // link.click()
      // document.body.removeChild(link)
    }
  }
}
</script>

<style scoped>
.font-red{
  color: #E62734 !important;
}

/deep/.ant-modal {
  top: 1px; /* 距离顶部50像素 */
}

/deep/.diyTableStyle {
  font-weight: bold;
  font-family: "黑体", "微软雅黑", "宋体", serif;
  color: #000000;
}

/deep/.diyTableStyle .ant-descriptions-row {
  border:1px solid #000000;
  font-family: "黑体", "微软雅黑", "宋体", serif;
  color: #000000;
  font-weight: bold;
}

/deep/.diyTableStyle .ant-descriptions-item-content{
  border-right: 1px solid #000000;
  padding: 10px 5px;
  font-weight: bold;
  font-family: "黑体", "微软雅黑", "宋体", serif;
  color: #000000;
}
/deep/.diyTableStyle .ant-descriptions-item-label{
  padding: 10px 5px;
  font-weight: bold;
  font-family: "黑体", "微软雅黑", "宋体", serif;
  color: #000000;
  background-color: #ffffff;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  border-right: 1px solid #000000;
}


/deep/.diyTableStyle_no_Border {
  font-weight: bold;
  font-family: "黑体", "微软雅黑", "宋体", serif;
  color: #000000;
}

/deep/.diyTableStyle_no_Border .ant-descriptions-row {
  font-family: "黑体", "微软雅黑", "宋体", serif;
  color: #000000;
  font-weight: bold;
}

/deep/.diyTableStyle_no_Border .ant-descriptions-item-content{
  font-weight: bold;
  font-family: "黑体", "微软雅黑", "宋体", serif;
  color: #000000;
}
/deep/.diyTableStyle_no_Border .ant-descriptions-item-label{
  font-weight: bold;
  font-family: "黑体", "微软雅黑", "宋体", serif;
  color: #000000;
  background-color: #ffffff;
}




</style>